import no from '@/locales/no.json'
import en from '@/locales/en.json'
export default defineI18nConfig(nuxt => ({
    legacy: false,
    locale: 'no',
    messages: {
        en,
        no
    }
}))
